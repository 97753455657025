<template>
  <div>
    <div class="box" v-show="ables">
      <img class="cha" @click="close" src="../assets/img/ding/icon-close.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>人员管理启用</h1>
        </div>
        <div class="accounts">
          <div class="xing">*</div>
          <label for="">帐号</label>
          <input class="accounts-input" type="text" v-model="user.username" readonly/>
        </div>
        <div class="verify">
          <div class="xing">*</div>
          <label for="">验证码</label>
          <input class="verify-input" type="text" id="smscode" placeholder="验证码" />
          <!-- <button @click="send(user.username)" id="gain">获取验证码</button> -->
          <el-button :plain="true" @click="send(user.username)" id="gain">获取验证码</el-button>
        </div>
         <div class="btn">
          <!-- <button class="set"     @click="submit(user.uid)">提交</button> -->
          <el-button class="set" :plain="true" @click="submit(user.uid)">提交</el-button>

          <button class="cancel"  @click="qu">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '../api/user';
let num = 60;
let timer;
export default {
    props:["isable","enableuid"],
    data() {
        return {
            num,
            timer,
            ables:this.isable,
            user:{},
            timeClock:'',
        }
    },
    methods: {
        async send(mobile) {
          let res = await api.usersendMessage(mobile);

          if(res.code == 1){
              gain.disabled = true;
              timer=setInterval(function(){
              num--;
              gain.innerHTML = num;
            
              if (num == 0) {
                  clearInterval(timer);
                  gain.innerHTML = '重新发送';
                  gain.disabled = false;
                  num = 60;
              } 
              },1000)
          }else{
            // alert(res.message);
            this.$message.warning(res.message)
          }
        },
        close() {
          this.ables = false;
          clearInterval(timer);
          gain.innerHTML = '获取验证码';
          gain.disabled = false;
          num = 60;
          smscode = '';
          // this.ables = false;
          
        },
        qu() {
          this.ables = false
        },
        async submit(uid){
            let code = smscode.value;
            if(code.length != 6){
              // alert('请输入正确的验证码');
              this.$message.warning('请输入正确的验证码')
              return false;
            }
            
            if(isNaN(code)){
              // alert('请输入正确的验证码');
              this.$message.warning('请输入正确的验证码')
              return false;
            }

            let res1 = await api.userDoEnable(uid,code);     

            if(res1.code == 1)
            {
              // alert('成功启用');
              this.$message.success('成功启用')
              this.$emit('enablesx',new Date().getTime());
              this.close();
            }else{
              // alert(res1.message);
              this.$message.warning(res1.message);
            }
              
        }
    },
    watch:{
      isable(newval){
        console.log('---',newval);
        this.ables = newval;
        this.num = 60;
      },
      async enableuid(newval){
        let res_userdetail = await api.userDetail(newval);      
        this.user = res_userdetail.data;
      }
    },
    beforeDestroy() {
      // 页面关闭（路由跳转）时清除定时器
      clearInterval(this.timer)
      this.timer = null;
      this.num = 60;
    },
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 22;
  // background-color: pink;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 600px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 160px;
  height: 26px;
  padding-top: 21px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.accounts,
.verify {
  // width: 430px;
  height: 40px;
  margin-bottom: 12px;
//   background-color: pink;
}
.accounts{
    margin-top: 30px;
}
.verify{
    margin-left: 33px;
}
.xing {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;
  margin-top: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
label {
  width: 28px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  color: #2a3346;
  opacity: 1;
}
.accounts-input {
  //width: 300px;
  height: 32px;
  margin-left: 48px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  outline: none;
}
.accounts-input:hover{
  border: 1px solid #c0c4cc;
}
.verify-input {
  //width: 190px;
  height: 32px;
  margin-left: 34px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  outline: none;
}
.verify-input:hover{
  border: 1px solid #c0c4cc;
}
input[type=text]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
#gain {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  color: #2A3346;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 118px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}
</style>
