<template>
  <div>
    <div class="box" v-if="tails">
      <img
        class="cha"
        @click="close"
        src="../assets/img/ding/cha.png"
        alt=""
      />
      <div class="box-main">
        <div class="main-nav">
          <h1>人员管理详情</h1>
        </div>
        <div class="num">
          <div class="xing">*</div>
          <label class="num-label" for="">帐号</label>
          <input
            class="num-input"
            type="text"
            id="username"
            v-model="userdetail.username"
            readonly
            v-if="isreadonly"
          />
          <input
            class="num-input"
            type="text"
            id="username"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxlength="11"
            placeholder="帐号必需是手机号"
            v-model="userdetail.username"
            v-else
          />
        </div>
        <div class="verify" v-if="isSms">
          <div class="xing">*</div>
          <label class="verify-label">验证码</label>
          <input class="verify-input1" type="text" v-model="smscode" placeholder="验证码" />
          <el-button :plain="true" @click="send(userdetail.username)" id="gain1">获取验证码</el-button>
        </div>
        <div class="num1">
          <div class="xing">*</div>
          <label class="num-label" for="">密码</label>
          <input
            class="num-input"
            type="text"
            id="password"
            placeholder=""
            v-model="password"
          />
        </div>
        <div class="verify">
          <div class="xing">*</div>
          <label class="verify-label" for="">确认密码</label>
          <input
            class="verify-input"
            type="text"
            id="password_repeat"
            placeholder=""
            v-model="password_repeat"
          />
        </div>
        <div class="num1">
          <div class="xing"></div>
          <label class="num-label" for="">姓名</label>
          <input
            class="num-input"
            type="text"
            id="newname"
            v-model="userdetail.name"
          />
        </div>
        <div class="verify">
          <div class="xing"></div>
          <label class="verify-label" for="">联系电话</label>
          <input
            class="verify-input"
            type="text"
            id="newtel"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxlength="11"
            v-model="userdetail.tel"
          />
        </div>
        <div class="role">
          <div class="xing"></div>
          <label class="role-label" for="">角色</label>
          <!--<input class="role-input" type="text" placeholder="管理员" />-->
          <el-select
            class="arr"
            v-model="form.region"
            placeholder="选择角色"
            @change="changerole"
          >
            <el-option label="管理员" value="1"></el-option>
            <el-option label="财务" value="2"></el-option>
            <el-option label="客服" value="3"></el-option>
          </el-select>
        </div>
        <div class="limit">
          <div class="xing"></div>
          <label class="limit-label" for="">权限</label>
          <div class="option">
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="nowrole"
              @change="handleCheckedListChange"
            >
              <el-checkbox
                v-for="item in listData"
                :label="parseInt(item.id)"
                :key="parseInt(item.id)"
                class="drag-item"
                border
              >
                <span>{{ item.name }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
          </div>
        </div>

        <div class="btn" v-if="!getlook">
          <el-button class="set" :plain="true" @click="save(userdetail.uid)"
            >保存</el-button
          >
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import { isArray } from "@vue/shared";

import * as api from "../api/user";

const form = reactive({
  name: "",
  region: "",
  date1: "",
  date2: "",
  delivery: false,
  type: [],
  resource: "",
  desc: "",
});
let num1 = 60;
let timer1;
export default {
  props: ["istail", "userinfo", "listData", "checkedLists", "isreadonly"],
  // props:["istail","uid"],
  data() {
    return {
      form,
      tails: this.istail,
      checkAll: false,
      isIndeterminate: false,
      rolelist: [],
      nowrole: [],
      isreadonly: this.isreadonly,
      userdetail: {
        uid: 0,
        username: "",
        name: "",
        tel: "",
      },
      from: {
        region: "",
      },
      password: "",
      password_repeat: "",
      role: "",
      isSms:false,
      smscode:'',
      num1,
      timer1,
    };
  },

  created() {},
  mounted() {},
  methods: {
    handleCheckAllChange(val) {
      this.nowrole = val
        ? this.listData.map((item) => {
            return parseInt(item.id);
          })
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedListChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.listData.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.listData.length;
      console.log(this.isIndeterminate);
      console.log("now", this.nowrole);
    },
    changerole(value) {
      if (value == 1) {
        this.nowrole = this.listData.map((item) => {
          return parseInt(item.id);
        });
      } else if (value == 2) {
        this.nowrole = [1,2,13];
      } else {
        if(this.getismain){
          this.nowrole = [1,2,3,5,7,12];
        }else{
          this.nowrole = [1,3,2];
        }
        
      }
      console.log("hhhhhhhhhhh:", form.region);
    },
    async save(uid) {
      // isreadonly 为true是从查看页面进入
      // 为false时是从添加页面进来
      if (!this.isreadonly) {
        this.nowrole.forEach((item, index) => {
          if (index == 0) {
            this.role = item;
          } else {
            this.role = this.role + "," + item;
          }
        });
        console.log("选项：", this.role);
        if (password.value != "" && password.value != password_repeat.value) {
          // alert('两次密码不一致');
          this.$message.warning("两次密码不一致");
          return false;
        }

        let data = {
          username: username.value,
          name: newname.value,
          tel: newtel.value,
          password: this.password,
          password_repeat: this.password_repeat,
          role: this.role,
          smsCode:this.smscode,

        };

        let res = await api.userAdd(data);
        if (res.code == 1) {
          this.$message.success("成功");
          this.$emit("detailsxx", 1);
          this.tails = false;
          Object.assign(this.$data, this.$options.data());
          this.close();
        } else if(res.code == 0){
          this.$message.warning(res.message);
        } else if(res.code == 2)
        {
          this.isSms = true;    
          this.$message.warning(res.message);
        }
      } else {
        if (password.value != "" && password.value != password_repeat.value) {
          this.$message.warning("两次密码不一致");
          return false;
        }
        let newrole = this.nowrole;
        let post_name = newname.value;
        let post_tel = newtel.value;
        let post_role = "";
        if (newrole.length > 0) {
          for (var i = 0; i < newrole.length; i++) {
            if (i == 0) post_role += newrole[i];
            else post_role += "," + newrole[i];
          }
        }
        let res = await api.userDoEdit(
          uid,
          password.value,
          password_repeat.value,
          post_name,
          post_tel,
          post_role
        );

        if (res.code == 1) {
          this.$message.success("成功");
          this.$emit("detailsx", 1);
          this.tails = false;
          Object.assign(this.$data, this.$options.data());
        } else {
          // alert(res.message);
          this.$message.warning(res.message);
        }
      }
    },
    close() {
      this.tails = false;
      if(this.isSms == true)
      {
        gain1.innerHTML = '获取验证码';
      }
      Object.assign(this.$data, this.$options.data()); //清空所有内容
      console.log("aaaaaaaaaaaaaaaaaa:", (form.region = ""), form.region);
      clearInterval(this.timer1)
      this.timer1= null;
      this.num1 = 60;

      
    },
     async send(mobile) {
          let res = await api.usersendMessage(mobile);

          if(res.code == 1){
              gain1.disabled = true;
              timer1=setInterval(function(){
              num1--;
              gain1.innerHTML = num1;
              console.log(num1);
            
              if (num1== 0) {
                  clearInterval(timer1);
                  gain1.innerHTML = '重新发送';
                  gain1.disabled = false;
                  num1 = 60;
              } 
              },1000)

          }else{
            // alert(res.message);
            this.$message.warning(res.message)
          }
        },
  },
  computed: {
    getismain() {
      return this.$store.state.ismain;
    },
    getlook()
    {
      return this.$store.state.lookonly;
    }
  },
  watch: {
    istail(newval) {
      console.log("---", newval);
      this.tails = newval;
    },
    isSms(newval)
    {
      console.log(newval);
    },
    checkedLists: function (newVal) {
      console.log(newVal);
      if (newVal) this.nowrole = Object.values(newVal);
    },
    userinfo: {
      handler: function (newval) {
        console.log("userinfo改变", newval);
        for (let key in this.userdetail) {
          this.userdetail[key] = newval[key];
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
      // 页面关闭（路由跳转）时清除定时器
      clearInterval(this.timer1)
      this.timer1 = null;
      this.num1 = 60;
    },
};
</script>

<style lang="less" scoped>
.arr {
  width: 130px;
  height: 40px;
}
.box {
  width: 640px;
  // height: 920px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 22;
}
.cha {
  float: left;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: 0;
}
.box-main {
  width: 600px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
  overflow-y: scroll;
}
.box-main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  padding-left: 30px;
  box-sizing: border-box;
}
h1 {
  height: 26px;
  padding-top: 20px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  line-height: 80px;
  opacity: 1;
}
.xing,
.xing1,
.xing2 {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
.xing {
  margin-top: 17px;
}
.xing1 {
  margin-top: 17px;
}
.xing2 {
  margin-top: 12px;
}
.num,
.num1,
.verify,
.role,
.limit {
  width: 400px;
  height: 40px;
}
.num {
  margin-top: 30px;
  margin-left: 30px;
}
.num1 {
  margin-top: 12px;
  margin-left: 33px;
}
.verify {
  margin-left: 33px;
  margin-top: 12px;
}
.role {
  margin-left: 33px;
  margin-top: 12px;
}
.limit {
  width: 500px;
  height: auto;
  margin-left: 33px;
  margin-top: 12px;
  //   background-color: pink;
}
.num-label,
.verify-label,
.role-label,
.limit-label {
  display: inline-block;
  width: 70px;
  margin-right: 20px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
}
// .num-label {
//   margin-right: 48px;
// }
// .verify-label {
//   margin-right: 20px;
// }
// .role-label {
//   float: left;
//   line-height: 32px;
//   margin-right: 48px;
// }
.num-input,
.verify-input {
  width: 260px;
  height: 32px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}
.verify-input1 {
  width: 150px;
  height: 32px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}
.num-input:hover {
  border: 1px solid #c0c4cc;
}
.verify-input:hover,.verify-input1:hover {
  border: 1px solid #c0c4cc;
}
input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
.role-input {
  float: left;
  width: 120px;
  height: 32px;
  background: #ffffff;
  margin-right: 10px;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  padding-left: 12px;
  box-sizing: border-box;
}
.option {
  margin-left: 95px;
  margin-top: -36px;
}
.el-checkbox {
  width: 160px;
  border: 0;
  padding-left: 0;
  margin-bottom: 10px;
}
.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 117px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  vertical-align: top;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}
#gain1 {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  color: #2A3346;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}
</style>
